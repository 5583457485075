import promptHtml from "bundle-text:./mobileno-prompt.html";
import { showToaster } from "../../scripts/functions";
import callingJson from "./codes.json";


function hideMobilePrompt () {
    document.querySelector('.mobileno-prompt').classList.remove('shown');
}

class MobileNoPrompt extends HTMLElement {
    connectedCallback() {
        this.innerHTML = promptHtml;
        const select = this.querySelector('select');

        callingJson.forEach(item => {
            const option = document.createElement("option");
            option.value =  item.code;    //item.dial_code;
            option.text = item.code + " " + item.dial_code;
            if (item.code == "US") {
                option.selected = true;
            }
            
            select.add(option);
        });


        const modal = this;

        this.querySelector('.close-btn').addEventListener('click', function() {
            hideMobilePrompt()
        });

        // let userCountry = "US";
        // let dialingCode = "+1";

        // fetch("https://ipinfo.io/json")
        // .then(res => res.json())
        // .then(data => {
        //     userCountry = data.country;
        //     const found = callingJson.find(item => item.code === userCountry);
        //     if(found) {
        //         dialingCode = found.dial_code;
        //     }
        // });

        // select.value = dialingCode;

        const sendLinkForm = this.querySelector("form");

        sendLinkForm.addEventListener("submit", function(e) {
            e.preventDefault();

            const submitBtn = sendLinkForm.querySelector('button');
            submitBtn.disabled = true;
        
            
            const formData = new FormData(sendLinkForm);

            const countryCode = formData.get('countryCode');
            const dialCode = callingJson.find(item => item.code === countryCode).dial_code;
            const phoneNo = formData.get("phoneNumber");
            formData.set("phoneNumber", dialCode+phoneNo);
            
            fetch("https://rvnz5g82bb.execute-api.us-east-1.amazonaws.com/Prod/sms",{
                method:'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                    body: JSON.stringify(Object.fromEntries(formData))
            })
            .then(function(response){
                if(response.ok) {
                    showToaster(true, "Link sent to your number");
                    sendLinkForm.reset();
                    hideMobilePrompt();
                } else {
                    showToaster(false, "Couldn't send link to your number");
                }
                submitBtn.disabled = false;
                return response;
            }).catch(function(error){
                submitBtn.disabled = false;
                showToaster(false, "Couldn't send link to your number");
            });
        })
        
    }
}

window.customElements.define('sg-mobileno-prompt', MobileNoPrompt);