import headerHtml from "bundle-text:./header.html";
// console.log(headerHtml)
class SgHeader extends HTMLElement {
    connectedCallback() {
        this.innerHTML = headerHtml;
        this.querySelector('#ham-menu').addEventListener('click', function() {
            document.querySelector('sg-modal-menu > footer').classList.add('shown');
        });

        this.querySelector('#header-btn').addEventListener('click', () => {
            document.querySelector('.mobileno-prompt').classList.add('shown')
        })
    }
}

window.customElements.define('sg-header', SgHeader);
