import footerHtml from "bundle-text:./footer.html";


class SgFooter extends HTMLElement {
    connectedCallback() {
        this.innerHTML = footerHtml;
    }
}

window.customElements.define('sg-footer', SgFooter);
