export function downloadAction() {
    if(/android/i.test(navigator.userAgent)) { // hide appstore
        window.location.href = "market://details?id=com.sgiggles"
        return
    }

    if(/iphone|ipad/i.test(navigator.userAgent)) { // hide playstore
       window.location.href = "https://apps.apple.com/us/app/sgiggles/id1472235735";
       return;
    }

    document.querySelector(".mobileno-prompt").classList.add('shown');
}

const toast = document.getElementById("snackbar-container");

export function showToaster(success, message) {
  if(message) {
    toast.querySelector("#snackbar").className = success ? 'success' : 'error';
    toast.querySelector("span#head-text").innerText = success ? 'Thank you' : 'Sorry';
    toast.querySelector('#msg').innerText = message;
    toast.classList.add("show");
    // setTimeout(hideToaster, 3000);
  }

}

function hideToaster() {
  toast.classList.remove("show");
}

if(toast){
    toast.querySelector("#close").addEventListener("click", hideToaster);
}
