import menuHtml from "bundle-text:../footer/footer.html";

class SgModalMenu extends HTMLElement {
    connectedCallback() {
        this.innerHTML = menuHtml;
        const footer = this.querySelector('footer');
        footer.classList.add('show-as-menu')
        this.querySelector('.close-btn').addEventListener('click', function() {
            footer.classList.remove('shown')
        });

        if(/android/i.test(navigator.userAgent)) { // hide appstore
            document.querySelectorAll('.app-store-link').forEach(element => element.style.display = "none")
        }

        if(/iphone|ipad/i.test(navigator.userAgent)) { // hide playstore
            document.querySelectorAll('.play-store-link').forEach(element => element.style.display = "none")
        }
        
    }
}

window.customElements.define('sg-modal-menu', SgModalMenu);